import plugin_vue3_A0OWXRrUgq from "C:/Users/user/git/im2.0_font/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Users/user/git/im2.0_font/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "C:/Users/user/git/im2.0_font/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Users/user/git/im2.0_font/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "C:/Users/user/git/im2.0_font/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "C:/Users/user/git/im2.0_font/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "C:/Users/user/git/im2.0_font/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "C:/Users/user/git/im2.0_font/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "C:/Users/user/git/im2.0_font/node_modules/nuxt/dist/app/plugins/payload.client.js";
import runtime_config_9dAVxnlU3J from "C:/Users/user/git/im2.0_font/plugins/runtime-config.js";
import plugin_client_i18n_EJtKqFefby from "C:/Users/user/git/im2.0_font/plugins/plugin.client.i18n.js";
import plugin_client_piniaPersistedstate_lsUE3yzXjW from "C:/Users/user/git/im2.0_font/plugins/plugin.client.piniaPersistedstate.js";
import plugin_client_vuetify_0I9Qb5vn0D from "C:/Users/user/git/im2.0_font/plugins/plugin.client.vuetify.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  runtime_config_9dAVxnlU3J,
  plugin_client_i18n_EJtKqFefby,
  plugin_client_piniaPersistedstate_lsUE3yzXjW,
  plugin_client_vuetify_0I9Qb5vn0D
]