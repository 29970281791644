import { default as _91channelUrl_93BiPOeAhe2hMeta } from "C:/Users/user/git/im2.0_font/pages/chat/[channelUrl].vue?macro=true";
import { default as forgetPwdMWO7LUwQRaMeta } from "C:/Users/user/git/im2.0_font/pages/forgetPwd.vue?macro=true";
import { default as conversationfOe3BqMLfEMeta } from "C:/Users/user/git/im2.0_font/pages/index/conversation.vue?macro=true";
import { default as customerCenterlgwI8yZXXfMeta } from "C:/Users/user/git/im2.0_font/pages/index/customerCenter.vue?macro=true";
import { default as feedbackQAk1OzOm43Meta } from "C:/Users/user/git/im2.0_font/pages/index/feedback.vue?macro=true";
import { default as historyxP6AA9WPsxMeta } from "C:/Users/user/git/im2.0_font/pages/index/history.vue?macro=true";
import { default as homelgV2rg7Z11Meta } from "C:/Users/user/git/im2.0_font/pages/index/home.vue?macro=true";
import { default as profileus5i9d7EA0Meta } from "C:/Users/user/git/im2.0_font/pages/index/profile.vue?macro=true";
import { default as assignoOxlBFkmmAMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/assign.vue?macro=true";
import { default as basicQvlLcUqdHAMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/basic.vue?macro=true";
import { default as blacklistFf1nUUx5F8Meta } from "C:/Users/user/git/im2.0_font/pages/index/setup/blacklist.vue?macro=true";
import { default as conversationTagQNUo9BPfxBMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/conversationTag.vue?macro=true";
import { default as customerTagZ7Cg0hSyAQMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/customerTag.vue?macro=true";
import { default as queueZ9qQZFEyVIMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/queue.vue?macro=true";
import { default as quickReplyszAixNNGT8Meta } from "C:/Users/user/git/im2.0_font/pages/index/setup/quickReply.vue?macro=true";
import { default as rolesqSaNIs8EMPMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/roles.vue?macro=true";
import { default as satisfactionrL5pz3peHzMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/satisfaction.vue?macro=true";
import { default as sensitiverqn2NJHsATMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/sensitive.vue?macro=true";
import { default as teamfhWPHFS7wnMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/team.vue?macro=true";
import { default as setupGuimYdbsnyMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/webAccess/setup.vue?macro=true";
import { default as webYuaOVMCJsDMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/webAccess/web.vue?macro=true";
import { default as webAccessZGYhh7ALFIMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup/webAccess.vue?macro=true";
import { default as setupBsGycNRgCyMeta } from "C:/Users/user/git/im2.0_font/pages/index/setup.vue?macro=true";
import { default as index4kxqcWqQmcMeta } from "C:/Users/user/git/im2.0_font/pages/index.vue?macro=true";
import { default as inviteUserVerifyk4nql2pzD0Meta } from "C:/Users/user/git/im2.0_font/pages/inviteUserVerify.vue?macro=true";
import { default as loginG091wSpguBMeta } from "C:/Users/user/git/im2.0_font/pages/login.vue?macro=true";
import { default as register92j8IRSPn3Meta } from "C:/Users/user/git/im2.0_font/pages/register.vue?macro=true";
import { default as registerVerifyfxTavAk8F6Meta } from "C:/Users/user/git/im2.0_font/pages/registerVerify.vue?macro=true";
import { default as resetPwdyuBLxIbT7tMeta } from "C:/Users/user/git/im2.0_font/pages/resetPwd.vue?macro=true";
import { default as verifyucwA5LdqumMeta } from "C:/Users/user/git/im2.0_font/pages/verify.vue?macro=true";
export default [
  {
    name: _91channelUrl_93BiPOeAhe2hMeta?.name ?? "chat-channelUrl",
    path: _91channelUrl_93BiPOeAhe2hMeta?.path ?? "/chat/:channelUrl()",
    meta: _91channelUrl_93BiPOeAhe2hMeta || {},
    alias: _91channelUrl_93BiPOeAhe2hMeta?.alias || [],
    redirect: _91channelUrl_93BiPOeAhe2hMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/chat/[channelUrl].vue").then(m => m.default || m)
  },
  {
    name: forgetPwdMWO7LUwQRaMeta?.name ?? "forgetPwd",
    path: forgetPwdMWO7LUwQRaMeta?.path ?? "/forgetPwd",
    meta: forgetPwdMWO7LUwQRaMeta || {},
    alias: forgetPwdMWO7LUwQRaMeta?.alias || [],
    redirect: forgetPwdMWO7LUwQRaMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/forgetPwd.vue").then(m => m.default || m)
  },
  {
    name: index4kxqcWqQmcMeta?.name ?? "index",
    path: index4kxqcWqQmcMeta?.path ?? "/",
    children: [
  {
    name: conversationfOe3BqMLfEMeta?.name ?? "index-conversation",
    path: conversationfOe3BqMLfEMeta?.path ?? "conversation",
    meta: conversationfOe3BqMLfEMeta || {},
    alias: conversationfOe3BqMLfEMeta?.alias || [],
    redirect: conversationfOe3BqMLfEMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/conversation.vue").then(m => m.default || m)
  },
  {
    name: customerCenterlgwI8yZXXfMeta?.name ?? "index-customerCenter",
    path: customerCenterlgwI8yZXXfMeta?.path ?? "customerCenter",
    meta: customerCenterlgwI8yZXXfMeta || {},
    alias: customerCenterlgwI8yZXXfMeta?.alias || [],
    redirect: customerCenterlgwI8yZXXfMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/customerCenter.vue").then(m => m.default || m)
  },
  {
    name: feedbackQAk1OzOm43Meta?.name ?? "index-feedback",
    path: feedbackQAk1OzOm43Meta?.path ?? "feedback",
    meta: feedbackQAk1OzOm43Meta || {},
    alias: feedbackQAk1OzOm43Meta?.alias || [],
    redirect: feedbackQAk1OzOm43Meta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/feedback.vue").then(m => m.default || m)
  },
  {
    name: historyxP6AA9WPsxMeta?.name ?? "index-history",
    path: historyxP6AA9WPsxMeta?.path ?? "history",
    meta: historyxP6AA9WPsxMeta || {},
    alias: historyxP6AA9WPsxMeta?.alias || [],
    redirect: historyxP6AA9WPsxMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/history.vue").then(m => m.default || m)
  },
  {
    name: homelgV2rg7Z11Meta?.name ?? "index-home",
    path: homelgV2rg7Z11Meta?.path ?? "home",
    meta: homelgV2rg7Z11Meta || {},
    alias: homelgV2rg7Z11Meta?.alias || [],
    redirect: homelgV2rg7Z11Meta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/home.vue").then(m => m.default || m)
  },
  {
    name: profileus5i9d7EA0Meta?.name ?? "index-profile",
    path: profileus5i9d7EA0Meta?.path ?? "profile",
    meta: profileus5i9d7EA0Meta || {},
    alias: profileus5i9d7EA0Meta?.alias || [],
    redirect: profileus5i9d7EA0Meta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/profile.vue").then(m => m.default || m)
  },
  {
    name: setupBsGycNRgCyMeta?.name ?? "index-setup",
    path: setupBsGycNRgCyMeta?.path ?? "setup",
    children: [
  {
    name: assignoOxlBFkmmAMeta?.name ?? "index-setup-assign",
    path: assignoOxlBFkmmAMeta?.path ?? "assign",
    meta: assignoOxlBFkmmAMeta || {},
    alias: assignoOxlBFkmmAMeta?.alias || [],
    redirect: assignoOxlBFkmmAMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/assign.vue").then(m => m.default || m)
  },
  {
    name: basicQvlLcUqdHAMeta?.name ?? "index-setup-basic",
    path: basicQvlLcUqdHAMeta?.path ?? "basic",
    meta: basicQvlLcUqdHAMeta || {},
    alias: basicQvlLcUqdHAMeta?.alias || [],
    redirect: basicQvlLcUqdHAMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/basic.vue").then(m => m.default || m)
  },
  {
    name: blacklistFf1nUUx5F8Meta?.name ?? "index-setup-blacklist",
    path: blacklistFf1nUUx5F8Meta?.path ?? "blacklist",
    meta: blacklistFf1nUUx5F8Meta || {},
    alias: blacklistFf1nUUx5F8Meta?.alias || [],
    redirect: blacklistFf1nUUx5F8Meta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/blacklist.vue").then(m => m.default || m)
  },
  {
    name: conversationTagQNUo9BPfxBMeta?.name ?? "index-setup-conversationTag",
    path: conversationTagQNUo9BPfxBMeta?.path ?? "conversationTag",
    meta: conversationTagQNUo9BPfxBMeta || {},
    alias: conversationTagQNUo9BPfxBMeta?.alias || [],
    redirect: conversationTagQNUo9BPfxBMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/conversationTag.vue").then(m => m.default || m)
  },
  {
    name: customerTagZ7Cg0hSyAQMeta?.name ?? "index-setup-customerTag",
    path: customerTagZ7Cg0hSyAQMeta?.path ?? "customerTag",
    meta: customerTagZ7Cg0hSyAQMeta || {},
    alias: customerTagZ7Cg0hSyAQMeta?.alias || [],
    redirect: customerTagZ7Cg0hSyAQMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/customerTag.vue").then(m => m.default || m)
  },
  {
    name: queueZ9qQZFEyVIMeta?.name ?? "index-setup-queue",
    path: queueZ9qQZFEyVIMeta?.path ?? "queue",
    meta: queueZ9qQZFEyVIMeta || {},
    alias: queueZ9qQZFEyVIMeta?.alias || [],
    redirect: queueZ9qQZFEyVIMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/queue.vue").then(m => m.default || m)
  },
  {
    name: quickReplyszAixNNGT8Meta?.name ?? "index-setup-quickReply",
    path: quickReplyszAixNNGT8Meta?.path ?? "quickReply",
    meta: quickReplyszAixNNGT8Meta || {},
    alias: quickReplyszAixNNGT8Meta?.alias || [],
    redirect: quickReplyszAixNNGT8Meta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/quickReply.vue").then(m => m.default || m)
  },
  {
    name: rolesqSaNIs8EMPMeta?.name ?? "index-setup-roles",
    path: rolesqSaNIs8EMPMeta?.path ?? "roles",
    meta: rolesqSaNIs8EMPMeta || {},
    alias: rolesqSaNIs8EMPMeta?.alias || [],
    redirect: rolesqSaNIs8EMPMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/roles.vue").then(m => m.default || m)
  },
  {
    name: satisfactionrL5pz3peHzMeta?.name ?? "index-setup-satisfaction",
    path: satisfactionrL5pz3peHzMeta?.path ?? "satisfaction",
    meta: satisfactionrL5pz3peHzMeta || {},
    alias: satisfactionrL5pz3peHzMeta?.alias || [],
    redirect: satisfactionrL5pz3peHzMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/satisfaction.vue").then(m => m.default || m)
  },
  {
    name: sensitiverqn2NJHsATMeta?.name ?? "index-setup-sensitive",
    path: sensitiverqn2NJHsATMeta?.path ?? "sensitive",
    meta: sensitiverqn2NJHsATMeta || {},
    alias: sensitiverqn2NJHsATMeta?.alias || [],
    redirect: sensitiverqn2NJHsATMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/sensitive.vue").then(m => m.default || m)
  },
  {
    name: teamfhWPHFS7wnMeta?.name ?? "index-setup-team",
    path: teamfhWPHFS7wnMeta?.path ?? "team",
    meta: teamfhWPHFS7wnMeta || {},
    alias: teamfhWPHFS7wnMeta?.alias || [],
    redirect: teamfhWPHFS7wnMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/team.vue").then(m => m.default || m)
  },
  {
    name: webAccessZGYhh7ALFIMeta?.name ?? "index-setup-webAccess",
    path: webAccessZGYhh7ALFIMeta?.path ?? "webAccess",
    children: [
  {
    name: setupGuimYdbsnyMeta?.name ?? "index-setup-webAccess-setup",
    path: setupGuimYdbsnyMeta?.path ?? "setup",
    meta: setupGuimYdbsnyMeta || {},
    alias: setupGuimYdbsnyMeta?.alias || [],
    redirect: setupGuimYdbsnyMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/webAccess/setup.vue").then(m => m.default || m)
  },
  {
    name: webYuaOVMCJsDMeta?.name ?? "index-setup-webAccess-web",
    path: webYuaOVMCJsDMeta?.path ?? "web",
    meta: webYuaOVMCJsDMeta || {},
    alias: webYuaOVMCJsDMeta?.alias || [],
    redirect: webYuaOVMCJsDMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/webAccess/web.vue").then(m => m.default || m)
  }
],
    meta: webAccessZGYhh7ALFIMeta || {},
    alias: webAccessZGYhh7ALFIMeta?.alias || [],
    redirect: webAccessZGYhh7ALFIMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup/webAccess.vue").then(m => m.default || m)
  }
],
    meta: setupBsGycNRgCyMeta || {},
    alias: setupBsGycNRgCyMeta?.alias || [],
    redirect: setupBsGycNRgCyMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index/setup.vue").then(m => m.default || m)
  }
],
    meta: index4kxqcWqQmcMeta || {},
    alias: index4kxqcWqQmcMeta?.alias || [],
    redirect: index4kxqcWqQmcMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inviteUserVerifyk4nql2pzD0Meta?.name ?? "inviteUserVerify",
    path: inviteUserVerifyk4nql2pzD0Meta?.path ?? "/inviteUserVerify",
    meta: inviteUserVerifyk4nql2pzD0Meta || {},
    alias: inviteUserVerifyk4nql2pzD0Meta?.alias || [],
    redirect: inviteUserVerifyk4nql2pzD0Meta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/inviteUserVerify.vue").then(m => m.default || m)
  },
  {
    name: loginG091wSpguBMeta?.name ?? "login",
    path: loginG091wSpguBMeta?.path ?? "/login",
    meta: loginG091wSpguBMeta || {},
    alias: loginG091wSpguBMeta?.alias || [],
    redirect: loginG091wSpguBMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/login.vue").then(m => m.default || m)
  },
  {
    name: register92j8IRSPn3Meta?.name ?? "register",
    path: register92j8IRSPn3Meta?.path ?? "/register",
    meta: register92j8IRSPn3Meta || {},
    alias: register92j8IRSPn3Meta?.alias || [],
    redirect: register92j8IRSPn3Meta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerVerifyfxTavAk8F6Meta?.name ?? "registerVerify",
    path: registerVerifyfxTavAk8F6Meta?.path ?? "/registerVerify",
    meta: registerVerifyfxTavAk8F6Meta || {},
    alias: registerVerifyfxTavAk8F6Meta?.alias || [],
    redirect: registerVerifyfxTavAk8F6Meta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/registerVerify.vue").then(m => m.default || m)
  },
  {
    name: resetPwdyuBLxIbT7tMeta?.name ?? "resetPwd",
    path: resetPwdyuBLxIbT7tMeta?.path ?? "/resetPwd",
    meta: resetPwdyuBLxIbT7tMeta || {},
    alias: resetPwdyuBLxIbT7tMeta?.alias || [],
    redirect: resetPwdyuBLxIbT7tMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/resetPwd.vue").then(m => m.default || m)
  },
  {
    name: verifyucwA5LdqumMeta?.name ?? "verify",
    path: verifyucwA5LdqumMeta?.path ?? "/verify",
    meta: verifyucwA5LdqumMeta || {},
    alias: verifyucwA5LdqumMeta?.alias || [],
    redirect: verifyucwA5LdqumMeta?.redirect || undefined,
    component: () => import("C:/Users/user/git/im2.0_font/pages/verify.vue").then(m => m.default || m)
  }
]